import React from "react";

import bologo from "../assets/logs/Bosch_logo.png";
import bulogo from "../assets/logs/Buderus_logo.png";
import alogo from "../assets/logs/Ariston_logo.png";
import dlogo from "../assets/logs/Ded_logo.png";

function Logs() {
  return (
    <div className="hidden  px-4  md:flex     py-2 gap-4 justify-center items-center  bg-gray-300 bg-opacity-25 rounded-2xl mt-20 overflow-hidden ">
      <div className="  overflow-hidden">
        <img
          src={bologo}
          className="  w-48 hover:scale-105 duration-300 cursor-pointer overflow-hidden  "
        />
      </div>
      <div>
        <img
          src={bulogo}
          className="w-48 hover:scale-110 duration-300 cursor-pointer overflow-hidden"
        />
      </div>
      <div>
        <img
          src={dlogo}
          className="w-48 bg-slate-100 bg-opacity-5 hover:scale-110 duration-300 cursor-pointer overflow-hidden"
        />
      </div>
      <div>
        <img
          src={alogo}
          className="w-48 hover:scale-110 duration-300 cursor-pointer px-2 overflow-hidden"
        />
      </div>
    </div>
  );
}

export default Logs;
