import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
// import wrench from "../../assets/wrench_s.png";

function Serwis() {
  const [send, setSend] = useState(0);

  // const { formState } = useForm();
  console.log("--- Serwis Form ---");
  const {
    handleSubmit,
    // errors,
    // control,
    reset,
    register,
    // formState: { isSubmitting },
  } = useForm();

  // const onSubmit = (data) => {
  //   return new Promise((resolve) => {
  //     console.log("----");
  //     setTimeout(() => {
  //       resolve();
  //       console.log(data);
  //       // alert({ data });
  //     }, 4000);
  //   });
  // };

  function onSubmit(data) {
    setSend(1);
    // return promise that resolves after 2 seconds
    // return new Promise((resolve) => {
    //   console.log(isSubmitting);
    //   console.log(formState);
    //   setTimeout(() => {
    //     resolve();
    //     console.log(data);
    //     console.log(isSubmitting);
    //     console.log(formState);
    //   }, 2000);
    // });
    // console.log(data);
    axios
      .post("https://termoserwis24.pl/pM/mail_zs.php", data, {
        // Add parameters here
      })
      .then((response) => {
        setSend(2);
        // console.log(response.data);
        // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzz");
        // Handle data
        if (response.data.trim() === "send") {
          setSend(9);
          reset();
        }

        if (response.data.trim() === "error") {
          setSend(3);
        }
        if (response.data.trim() === "") {
          // console.log("nuLLl?");
          setSend(3);
        }
      })
      .catch((error) => {
        console.log(error);
        setSend(3);
      });
  }

  // console.log(watch("example")); // watch input value by passing the name of it

  return (
    <div className=" overflow-hidden scrollbar-hide">
      <motion.div
        //   className=" w-full h-screen bg-gradient-to-r from-gray-400 via-gray-300 to-gray-200"
        className="bgboferta w-full h-screen "
        initial={{ opacity: 0, y: 150, x: 0 }}
        animate={{ opacity: 1, y: 5, x: 0 }}
        transition={{ duration: 0.4 }}
        exit={{ opacity: 0 }}
      >
        {/* main div  */}
        <div className=" max-w-[1540px] w-full h-screen mx-auto pt-24  rounded-lg  ">
          <div className="bgbser rounded-lg ">
            <div
              className=" w-full h-fit text-center text-gray-700 font-semibold shadow-slate-500
         text-shadow-md text-xl sm:text-1xl md:text-2xl bg-slate-200 bg-opacity-95
         pb-24 rounded-lg
         "
            >
              {/* Form`` */}

              {/* <form onSubmit={handleSubmit(onSubmit)} className="text-xl">
            <input {...register("test", { required: true })} required />
            <input type="submit" />
          </form> */}

              {/* <form onSubmit={handleSubmit(onSubmit)} className="text-xl">
            <input {...register("test", { required: true })} required />
          </form> */}

              <h1 className=" font-sans pt-2 text-lg md:text-4xl   ">
                Zgłoszenie serwisowe
              </h1>

              <motion.div
                className=" flex font-Inter text-gray-900 "
                initial={{
                  opacity: 1,
                  y: 150,
                }}
                animate={{
                  opacity: 1,
                  y: 5,
                }}
                transition={{ duration: 0.7 }}
                exit={{ opacity: 0 }}
              >
                <form
                  className="px-4 rounded mx-auto max-w-3xl w-full my-0 inputs space-y-6 text-gray-600   text-sm      md:text-lg "
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="">
                    {/* <p className="text-gray-600">
                  Changes you make will be visible to other users
                </p> */}
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <label>Imię / Nazwisko</label>
                      <input
                        className="border border-gray-400 px-4 py-2 rounded w-full focus:outline-none focus:border-red-800 bg-slate-100"
                        {...register("imie", { required: true })}
                        required
                      />
                    </div>
                    <div className="w-1/2">
                      <label>Telefon</label>
                      <input
                        className="border border-gray-400 px-4 py-2 rounded w-full focus:outline-none focus:border-red-800 bg-slate-100"
                        {...register("telefon", { required: true })}
                        required
                      />
                    </div>
                  </div>

                  {/* next  */}
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <label>Adres email</label>
                      <input
                        className="border border-gray-400 px-4 py-2 rounded w-full focus:outline-none focus:border-red-800 bg-slate-100"
                        {...register("email", { required: true })}
                        required
                      />
                    </div>
                    <div className="w-1/2">
                      <label>Adres (ulica nr.domu)</label>
                      <input
                        className="border border-gray-400 px-4 py-2 rounded w-full focus:outline-none focus:border-red-800 bg-slate-100"
                        {...register("adres", { required: true })}
                        required
                      />
                    </div>
                  </div>

                  {/* next  */}
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <label>Miejscowość</label>
                      <input
                        className="border border-gray-400 px-4 py-2 rounded w-full focus:outline-none focus:border-red-800 bg-slate-100"
                        {...register("miasto", { required: true })}
                        required
                      />
                    </div>
                    <div className="w-1/2">
                      <label>Kod pocztowy</label>
                      <input
                        className="border border-gray-400 px-4 py-2 rounded w-full focus:outline-none focus:border-red-800 bg-slate-100"
                        {...register("kodp", { required: false })}
                      />
                    </div>
                  </div>

                  {/* next  */}
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <label>Rodzaj zgłoszenia</label>
                      <select
                        className="border border-gray-400 px-4 py-2 rounded w-full focus:outline-none focus:border-red-800 bg-slate-100"
                        {...register("rzglo", { required: true })}
                        required
                      >
                        <option>Pierwsze uruchomienie</option>
                        <option>Naprawa gwarancyjna</option>
                        <option>Naprawa pogwarancyjna</option>
                        <option>Prezegląd konserwacja</option>
                        <option>Doradztwo techniczne</option>
                      </select>
                    </div>
                    <div className="w-1/2">
                      <label> Nazwa kotła / typ</label>
                      <input
                        className="border border-gray-400 px-4 py-2 rounded w-full focus:outline-none focus:border-red-800 bg-slate-100"
                        {...register("nazwakot", { required: true })}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label>Opis zgłoszenia</label>
                    <textarea
                      rows="3"
                      className="border border-gray-400 px-4 py-2 rounded w-full focus:outline-none focus:border-red-800 bg-slate-100"
                      placeholder="..."
                      lang="pl"
                      spellCheck="false"
                      {...register("opis", { required: false })}
                      required
                    ></textarea>
                  </div>
                  <div>
                    {send === 1 && (
                      <p className="pb-1 font-Inter text-gray-800">
                        Wysyłanie ...{" "}
                      </p>
                    )}

                    {send === 9 && (
                      <p className="pb-1 font-Inter text-green-800">
                        Zgłoszenie zostało wyslane..{" "}
                      </p>
                    )}
                    {send === 3 && (
                      <p className="pb-1 font-Inter text-red-800">
                        Wystąpił błąd.. Proszę spróbowć pózniej ..
                      </p>
                    )}
                    {/* <p>{send}</p> */}

                    <input
                      type="submit"
                      // value={}

                      className="border border-gray-400 px-4 mt-1 py-2 rounded focus:outline-none focus:border-red-800 bg-slate-200 w-1/2"
                    />
                  </div>
                </form>
                {/* <motion.div
                className="bgbser min-h-screen absolute max-w-[1540px]"
                initial={{ opacity: 1, y: 0 }}
                animate={{ opacity: 0, y: 0 }}
                transition={{ duration: 1.4, delay: 0.5 }}
                exit={{}}
              ></motion.div> */}
              </motion.div>

              {/* end foRm */}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Serwis;
