import React from "react";
import pbud from "../assets/karta/pbud.jpg";
import ariston from "../assets/karta/ariston.jpg";
import dede from "../assets/karta/dede.png";
import woda from "../assets/karta/woda.jpg";
import budowa from "../assets/karta/schemat.jpg";
import gas from "../assets/karta/gas.jpg";
// import gas2 from "../assets/karta/gas2.jpg";
import gas3 from "../assets/karta/palnik2.jpg";
import solbud from "../assets/karta/solbud.jpg";
import rekuperacja from "../assets/karta/hala.jpg";
import gazex from "../assets/karta/gazex.png";

import { motion } from "framer-motion";

import FadeIn from "react-fade-in";
import ludzik from "../assets/L1red1czarny_blur.png";

import { BsArrowDownSquare } from "react-icons/bs";
// import Test from "./Test";

import { useRef, useState } from "react";

import { NavLink } from "react-router-dom";
// import kluczN from "../assets/svg/wrench-nice.svg";

// const Karta1 = () => {
//   return (
//     <div
//       className=" text-gray-600 font-sans bg-gray-100 shadow-xl font-medium  max-w-[600px] mx-auto my-2 text-center rounded-t-xl hover:scale-[101%] duration-300 rounded-2xl
//      "
//     >
//       <div className="flex justify-center flex-col ">
//         {/* <div>
//           <img src={budair} width="100%" />
//         </div> */}
//         <div className="bg-gray-200 min-h-[80px] rounded-xl ">
//           <h2 className="pt-4 ">Pompy Ciepła</h2>
//         </div>
//         <div className="bg-gray-100 pt-4 font-sans mx-4 text-lg">
//           <span>
//             <p className=" font-semibold">
//               Pompa ciepła to wydajne i ekonomiczne ogrzewanie w Twoim domu.
//             </p>{" "}
//             <p>
//               <hr className=" bg-slate-400"></hr>
//             </p>
//             <p className="pt-4 overflow-scroll">
//               Pompa ciepła to urządzanie, które zgodnie ze swoją nazwą, ma na
//               celu przetransportować ciepło zawarte w jakimś źródle do czynnika
//               grzewczego, podnosząc jego temperaturę. Przede wszystkim jest to
//               urządzenie wykorzystujące zasoby naturalne, które znajdują się w
//               środowisku, takie jak ciepło wód gruntowych czy powietrza – czyli
//               źródła – do ogrzania naszego domu oraz wody której w nim używamy.
//             </p>
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

const Karta2 = () => {
  return (
    <div className=" w-[350px]  mx-auto  ">
      <div className=" shadow-2xl border rounded-lg max-w-sm bg-gray-800 border-gray-400 overflow-hidden h-full ">
        <a href="#">
          <img
            className="rounded-t-lg object-cover w-96 max-h-56 min-h-56 h-56 hover:scale-110 ease-in duration-300 "
            src={pbud}
            alt=""
            // height="300"
          />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className=" font-bold text-2xl tracking-tight mb-0 text-gray-200 font-Roboto">
              Montaż i serwis pomp ciepła marki Buderus i De Dietrich
            </h5>
          </a>
          {/* <p className="font-normal  mb-0 text-gray-400">
            Zaletami pomp ciepła jest ich wysoka efektywność energetyczna,
            niskie koszty eksploatacji oraz korzyści dla środowiska dzięki
            redukcji emisji gazów cieplarnianych.
          </p> */}
          {/* <a
            href="#"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  );
};

const Karta3 = () => {
  return (
    <div className="w-[350px]  mx-auto">
      <div className=" shadow-2xl border  rounded-lg max-w-sm bg-gray-800 border-gray-400 overflow-hidden ">
        <a href="#">
          <img
            className="rounded-t-lg object-cover w-96  max-h-56 min-h-56 hover:scale-110 ease-in duration-300"
            src={dede}
            alt=""
          />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className=" font-bold text-2xl tracking-tight mb-2 text-gray-200 font-Roboto">
              Montaż i serwis kotłow gazowych
            </h5>
          </a>
          {/* <p className="font-normal  mb-3 text-gray-400">
            Kotły gazowe są popularnym rozwiązaniem do ogrzewania domów i
            budynków, dzięki swojej wysokiej wydajności i niskim kosztom
            eksploatacji.
            <br />
          </p> */}
          {/* <a
            href="#"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> */}
          <div className=" min-h-[35px]"></div>
        </div>
      </div>
    </div>
  );
};
const Karta4 = () => {
  return (
    <div className="w-[350px]  mx-auto">
      <div className=" shadow-2xl border  rounded-lg max-w-sm bg-gray-800 border-gray-400 overflow-hidden">
        <a href="#">
          <img
            className="rounded-t-lg w-96 max-h-56 min-h-56 hover:scale-110 ease-in duration-300 "
            src={woda}
            alt=""
          />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className=" font-bold text-2xl tracking-tight mb-2 text-gray-200 font-Roboto">
              Uzdatnianie wody
            </h5>
          </a>
          <div className=" min-h-[56px]"></div>
          {/* <p className="font-normal mb-3 text-gray-400">
            Systemy uzdatniania wody są niezbędne do zapewnienia czystej i
            bezpiecznej wody pitnej, zwłaszcza w obszarach o złej jakości wody.
          </p> */}
          {/* <a
            href="#"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  );
};

const Karta5 = () => {
  return (
    <div className="w-[350px]  mx-auto">
      <div className=" shadow-2xl border  rounded-lg max-w-sm bg-gray-800 border-gray-400 overflow-hidden">
        <a href="#">
          <img
            className="rounded-t-lg w-96  max-h-56 h-64 min-h-56 hover:scale-110 ease-in duration-300 "
            src={budowa}
            alt=""
            // width="100px"
          />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className=" font-bold text-2xl tracking-tight mb-2 text-gray-200 font-Roboto">
              Budowa nowych instalacji grzewczych, modernizacja kotłowni
            </h5>
          </a>
          <p className="font-normal mb-3 text-gray-400">
            {/* <div className=" h-[6px]" /> */}
          </p>
          {/* <a
            href="#"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  );
};
const Karta6 = () => {
  return (
    <div className="w-[350px]  mx-auto">
      <div className=" shadow-2xl border  rounded-lg max-w-sm bg-gray-800 border-gray-400 overflow-hidden">
        <a href="#">
          <img
            className="rounded-t-lg w-96 max-h-56 min-h-56 hover:scale-110 ease-in duration-300 "
            src={gas}
            alt=""
            // width="100px"
          />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className=" font-bold text-2xl tracking-tight mb-2 text-gray-200 font-Roboto">
              Przeglądy okresowe sprawności instalacji gazowych
            </h5>
          </a>
          <p className="font-normal mb-3 text-gray-400"></p>
          {/* <a
            href="#"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  );
};
const Karta7 = () => {
  return (
    <div className="w-[350px]  mx-auto">
      <div className=" shadow-2xl border  rounded-lg max-w-sm bg-gray-800 border-gray-400 overflow-hidden">
        <a href="#">
          <img
            className="rounded-t-lg object-cover w-96 max-h-56 min-h-56 hover:scale-110 ease-in duration-300 "
            src={gazex}
            alt=""
            // width="100px"
          />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className=" font-bold text-2xl tracking-tight mb-2 text-gray-200 font-Roboto">
              Automatyczny System Detekcji Gazu GAZEX
            </h5>
          </a>
          <p className="font-normal mb-3 text-gray-400"></p>
          <div className=" h-[32px]" />
          {/* <a
            href="#"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  );
};
const Karta8 = () => {
  return (
    <div className="w-[350px]  mx-auto">
      <div className=" shadow-2xl border  rounded-lg max-w-sm bg-gray-800 border-gray-400 overflow-hidden">
        <a href="#">
          <img
            className="rounded-t-lg object-contain w-96 max-h-56 min-h-56 hover:scale-110 ease-in duration-300  bg-[#2d2c2c]"
            src={gas3}
            alt=""
            // width="100px"
          />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className=" font-bold text-2xl tracking-tight mb-2 text-gray-200 font-Roboto">
              Montaż i serwis przemysłowych palników gazowych i olejowych
            </h5>
          </a>
          <p className="font-normal mb-3 text-gray-400"></p>
          {/* <div className=" h-[32px]" /> */}
          {/* <a
            href="#"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  );
};
const Karta9 = () => {
  return (
    <div className="w-[350px]  mx-auto">
      <div className=" shadow-2xl border  rounded-lg max-w-sm bg-gray-800 border-gray-400 overflow-hidden">
        <a href="#">
          <img
            className="rounded-t-lg w-96 max-h-56 min-h-56 h-56 hover:scale-110 ease-in duration-300 "
            src={solbud}
            alt=""
            // width="100px"
          />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className=" font-bold text-2xl tracking-tight mb-2 text-gray-200 font-Roboto">
              Układy solarne
            </h5>
          </a>
          {/* <p className="font-normal mb-3 text-gray-400">
            Dzięki systemowi solarnemu energia słoneczna może być przechwytywana
            i wykorzystywana do produkcji ciepłej wody i ogrzewania.
          </p> */}
          <div className=" h-[56px]" />
          {/* <a
            href="#"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  );
};

const Karta10 = () => {
  return (
    <div className="w-[350px]  mx-auto">
      <div className=" shadow-2xl border  rounded-lg max-w-sm bg-gray-800 border-gray-400 overflow-hidden">
        <a href="#">
          <img
            className="rounded-t-lg w-96 max-h-56 min-h-56 hover:scale-110 ease-in duration-300 "
            src={rekuperacja}
            alt=""
            // width="100px"
          />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className=" font-bold text-2xl tracking-tight mb-2 text-gray-200 font-Roboto">
              Ogrzewanie hal i budynków wielkopowierzchniowych
            </h5>
          </a>
          <div className=" h-[24px]" />
          {/* <p className="font-normal mb-3 text-gray-400">
                      Rekuperacja to proces odzyskiwania ciepła z powietrza i
            wykorzystania go do ogrzewania lub chłodzenia pomieszczeń.
            <div className=" h-[24px]" />
          </p> */}
          {/* <a
            href="#"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  );
};

function Oferta() {
  const [scrl, setScrl] = useState(false);

  // console.log(window.innerHeight);

  // const top = useRef(null);
  const karty = useRef(null);

  // const sTop = () => {
  //   top.current?.scrollIntoView({ behavior: "smooth" });
  // };

  const sKarty = () => {
    karty.current?.scrollIntoView({ behavior: "smooth" });
    //setValue(valueRef.current);
  };

  // const wiecejRef = useRef();

  const mscro = (e) => {
    // console.log("scrolling!", e.target.scrollTop);
    // console.log(karty.current);
    if (e.target.scrollTop >= 777) {
      setScrl(true);
    } else {
      setScrl(false);
    }
    // console.log(scrl);
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 0 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0 }}
      name="oferta"
      className="bgboferta w-full h-screen    "
      onScroll={(e) => mscro(e)}
    >
      <div
        className="flex flex-col w-full min-h-screen bg-gradient-to-b from-gray-200/50 via-gray-300/70 to-gray-400/60 
             "
      >
        <div className=" max-w-[1440px] w-full h-full mx-auto mb-12   ">
          {/* gora z logo */}
          <div className=" w-full min-h-screen">
            <div className="flex rounded items-center  shadow-md text-shadow-lg font-sans gap-0  2xl:gap-2 pb-6 pt-28 lg:pt-32 xl:pt-28  2xl:pt-32 ">
              {/* Ludzik  */}
              <motion.div
                initial={{ opacity: 0, x: -30 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: 0.8,
                    duration: 0.3,
                  },
                }}
                className="hidden lg:flex lg:w-[1800px] "
              >
                <img
                  src={ludzik}
                  alt="brak_ludzika"
                  width={300}
                  //height={100}
                  className=" hover:scale-105 duration-200 ml-12 2xl:pt-2"
                />
              </motion.div>
              {/* koniec ludzik  */}
              <div className="flex flex-col ">
                <motion.div
                  className="flex justify-center space-x-2 lg:justify-center  2xl:justify-end lg:w-[90%]  xl:w-[90%] 2xl:w-full   mr-8 lg:mr-4 "
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 1, x: 0 }}
                >
                  <div
                    className=" first-letter:text-5xl lg:first-letter:text-8xl first-letter:font-bold first-letter:text-red-600 text-4xl lg:text-7xl text-slate-700 font-semibold  lg:w-72  
                      lg:mr-0 "
                  >
                    TERMO
                  </div>
                  <div className=" first-letter:text-5xl lg:first-letter:text-8xl first-letter:font-bold first-letter:text-red-600 text-4xl lg:text-7xl text-slate-700 font-semibold lg:w-72">
                    SERWIS
                  </div>
                </motion.div>
                <motion.div
                  className="mt-2 bg-slate-300 bg-opacity-20 rounded-xl   text-slate-700  text-sm lg:text-2xl flex  justify-end mr-2 pt-2 lg:w-[86%]  xl:w-[90%] 2xl:w-full"
                  initial={{ opacity: 0, x: 0 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 1, x: 0 }}
                >
                  <p className=" m-4  lg:m-auto">
                    Od 15 lat zajmujemy się serwisem urządzeń grzewczych.
                    Świadczymy również usługi profesjonalnego doradztwa
                    innowacyjnych rozwiązań technicznych oraz doboru urządzeń i
                    ich mocy. Posiadamy profesjonalne narzędzia diagnostyczne
                    oraz części zamienne aby szybko zdiagnozować i wyeliminować
                    przyczynę awarii.
                  </p>
                </motion.div>
              </div>
            </div>

            {/* oferta */}
            <div className=" mt-8 rounded shadow-md text-shadow-lg font-sans pb-6 ">
              <FadeIn delay={300}>
                <p className="text-3xl lg:text-4xl xl:text-4xl 2xl:text-5xl text-slate-700 font-semibold font-sans">
                  Oferujemy:
                </p>
              </FadeIn>
              <div className="pt-4 lg:pt-8 grid grid-cols-1 lg:grid-cols-2">
                <div>
                  <FadeIn delay={500}>
                    <ul className="text-xl lg:text-2xl xl:text-2xl 2xl:text-3xl text-slate-700  font-sans text-left ml-12 ">
                      <li className=" my-1 text-shadow-md">
                        <span className="box bg-red-600"></span> sprzedaż,
                        montaż i pierwsze uruchomienie
                      </li>
                      <li className=" my-1 text-shadow-md">
                        <span className="box bg-red-600 "></span> serwis
                        gwarancyjny i pogwarancyjny
                      </li>
                      <li className=" my-1 text-shadow-md">
                        <span className="box bg-red-600 "></span> przeglądy
                        gwarancyjne
                      </li>
                      <li className=" my-1 text-shadow-md">
                        <span className="box bg-red-600 "></span> przeglądy
                        okresowe
                      </li>
                      <li className=" my-1 text-shadow-md">
                        <span className="box bg-red-600 "></span> naprawy i
                        usuwanie awarii
                      </li>
                    </ul>
                  </FadeIn>
                </div>

                <div>
                  <FadeIn delay={700}>
                    <ul className="text-xl lg:text-2xl xl:text-2xl 2xl:text-3xl text-slate-700  font-sans text-left ml-12">
                      <li className=" my-1">
                        <span className="box bg-red-600 "></span> profesjonalne
                        doradztwo techniczne
                      </li>
                      <li className=" my-1">
                        <span className="box bg-red-600 "></span> ekspertyzy
                        techniczne
                      </li>
                      <li className=" my-1">
                        <span className="box bg-red-600 "></span>
                        kontrole szczelności instalacji gazowej
                      </li>
                      <li className=" my-1">
                        <span className="box bg-red-600 "></span> kontrole
                        składu spalin (obecność czadu)
                      </li>
                      <li className=" my-1">
                        <span className="box bg-red-600 "></span>usuwanie
                        kamienia kotłowego, uzdatnianie wody
                      </li>
                    </ul>
                  </FadeIn>
                </div>
              </div>
            </div>
            {/* button  */}
            {!scrl ? (
              <div
                // ref={wiecejRef}
                className="hidden lg:flex  bottom-8 right-10  items-center font-sans  mt-1 text-center justify-center animate-pulse hover:cursor-pointer"
              >
                <button
                //  onClick={wiecejClick}
                >
                  <p>Więcej</p>
                  <BsArrowDownSquare size={50} onClick={sKarty} className=" " />
                </button>
              </div>
            ) : null}
          </div>
          {/* konie o firmnie i oferta */}
          {/* {!start ? ( */}

          {/* ) : null} */}
          {/* koniec 1444 */}
          {/* KONT OFERTA */}
          {/* Scrolllllllllllllllllllll  start */}
          <div
            ref={karty}
            className=" max-w-[1440px] w-full  m-auto grid grid-cols-1 md:grid-cols-2
           lg:grid-cols-2 xl:grid-cols-3
              gap-4 rounded shadow-md justify-center text-center pt-2 mb-6 pb-6
               scroll-m-24"
          >
            <motion.div
              className=" flex justify-center  "
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
            >
              <Karta5 />
            </motion.div>
            <motion.div
              className=" flex justify-center "
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.3,
              }}
            >
              <Karta6 />
            </motion.div>
            <motion.div
              className=" flex justify-center "
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.6,
              }}
            >
              <Karta7 />
            </motion.div>
            {/* ///////////////////////////////////////////////////  */}
            {/* pozoostale trzy w dol  */}
            <motion.div
              className=" flex justify-center  "
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
            >
              <Karta2 />
            </motion.div>
            <motion.div
              className=" flex justify-center "
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.3,
              }}
            >
              <Karta3 />
            </motion.div>
            <motion.div
              className=" flex justify-center "
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.6,
              }}
            >
              <Karta8 />
            </motion.div>
            <motion.div
              className=" flex justify-center "
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.2,
              }}
            >
              <Karta10 />
            </motion.div>
            <motion.div
              className=" flex justify-center "
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.5,
              }}
            >
              <Karta9 />
            </motion.div>
            <motion.div
              className=" flex justify-center "
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.7,
              }}
            >
              <Karta4 />
            </motion.div>
            {/* <Test /> */}
          </div>
          {/* zamow servis */}
          {/* <div className="hidden xl:flex justify-center items-center opacity-90 ">
          <Link to="/" smooth={true}>
            <div className=" bg-red-600 h-48 w-22  fixed z-90 bottom-1/2 right-0 rounded-l-lg shadow-2xl shadow-gray-800 p-4 cursor-pointer hover:scale-110 ease-in duration-300">
              <img src={kluczN} className="w-12 pt-2 mx-auto " />

              <div className=" pt-4 font-sans  text-stone-200 text-lg w-full text-shadow-xl">
                <p>Zamów</p>
                <p>Serwis</p>
              </div>
            </div>
          </Link>
          </div> */}
        </div>{" "}
        {/* koniec 1444 */}
      </div>{" "}
      <div className="hidden lg:flex justify-center items-center opacity-90 ">
        <NavLink to="/serwis">
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0, transition: { type: "linear", delay: 0.7 } }}
            whileHover={{ scale: 1.07, transition: { duration: 0.2 } }}
            className=" bg-red-600  fixed z-90 bottom-1/2 right-0 rounded-l-lg shadow-2xl shadow-gray-800 p-4 cursor-pointer md:h-40 md:w-20 2xl:h-48 2xl:w-24"
          >
            <motion.img
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                rotateY: 360,
                transition: {
                  duration: 1,
                  type: "spring",
                  damping: 10,
                  mass: 0.75,
                  stiffness: 20,
                  delay: 0.7,
                },
              }}
              whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 4px gray",
              }}
              src={ludzik}
              className="w-12 pt-2 mx-auto shadow-2xl "
              alt=""
            />

            <div className=" pt-4 font-sans  text-stone-200 text-lg w-full text-shadow-xl">
              <p>Zamów</p>
              <p>Serwis</p>
            </div>
          </motion.div>
        </NavLink>
      </div>
    </motion.div>
  );
}

export default Oferta;
