import "./App.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./comps/Navbar";
import Loader from "./comps/Loader";
import Aroutes from "./comps/Aroutes";
import { AnimatePresence } from "framer-motion";
// import { AnimatePresence } from "framer-motion";

//const Dashboard = React.lazy(() => import("./pages/Dashboard"));
//const Main = React.lazy(() => import("./comps/Main"));

function App() {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 2800);
  //   // console.log("1111");
  // }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {/* {loading === false ? (
          <>
            <Navbar /> */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 3 }}
        >
          <Navbar />
        </motion.div>
        <Aroutes />
        {/* </>
        ) : (
          <Loader />
        )} */}
      </BrowserRouter>
    </div>
  );
}

export default App;
