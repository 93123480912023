import React, { useState } from "react";

import { motion } from "framer-motion";
// import { Link } from "react-scroll";
// import kluczN from "../assets/svg/wrench-nice.svg";
import ludzik from "../assets/L1red1czarny_blur.png";

import { AiOutlinePhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import Mapa_embed from "./Mapa_embed";
import { useForm } from "react-hook-form";
import axios from "axios";
import { NavLink } from "react-router-dom";

const Kontakt = () => {
  const [send, setSend] = useState(0);

  const {
    handleSubmit,
    // errors,
    // control,
    reset,
    register,
    // formState: { isSubmitting },
  } = useForm();

  function onSubmit(data) {
    setSend(1);
    // return promise that resolves after 2 seconds
    // return new Promise((resolve) => {
    //   console.log(isSubmitting);
    //   console.log(formState);
    //   setTimeout(() => {
    //     resolve();
    //     console.log(data);
    //     console.log(isSubmitting);
    //     console.log(formState);
    //   }, 2000);
    // });
    // console.log(data);
    axios
      .post("https://termoserwis24.pl/pM/mail_kon.php", data, {
        // Add parameters here
      })
      .then((response) => {
        setSend(2);
        // console.log(response.data);
        // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzz");
        // Handle data
        if (response.data.trim() === "send") {
          setSend(9);
          reset();
        }

        if (response.data.trim() === "error") {
          setSend(3);
        }
        if (response.data.trim() === "") {
          // console.log("nuLLl?");
          setSend(3);
        }
      })
      .catch((error) => {
        console.log(error);
        setSend(3);
      });
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setName("");
  //   setPhone("");
  //   setEmail("");
  //   setSubject("");
  //   setMessage("");
  //   console.log(name);
  //   ///alert("....");
  //   setModon(!modon);
  // };

  const [modon, setModon] = useState(false);

  const Modal = () => {
    return (
      <motion.div
        initial={{ opacity: 1, x: 0 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}
      >
        {/* <!-- Overlay element --  */}

        <div className="fixed inset-0   w-screen h-screen  bg-gray-600 bg-opacity-30"></div>
        {/* <!-- The dialog --> */}
        <div
          id="dialog"
          className=" fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] md:w-[20%] bg-white bg-opacity-95 rounded-md px-8 py-6 space-y-5 drop-shadow-lg "
        >
          <h1 className="text-xl font font-sans font-semibold text-slate-600 text-shadow-sm">
            Dziękujemy
          </h1>
          <div className="py-5 border-t border-b font-sans border-gray-300 text-slate-600 text-shadow-sm md:text-lg">
            <p>Wiadomość została wyslana ..</p>
          </div>
          <div className="flex justify-end">
            {/* <!-- This button is used to close the dialog --> */}
            <button
              id="close"
              className="px-5 py-2 bg-gray-500 hover:bg-slate-700 font-sans text-white cursor-pointer rounded-md text-shadow-sm"
              onClick={() => {
                setModon(!modon);
              }}
            >
              Zamknij
            </button>
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <motion.div
      className="bgbkontakt"
      // initial={{ opacity: 0, x: 0 }}
      // animate={{ opacity: 1, x: 0 }}
      // exit={{ opacity: 0, x: 0 }}
    >
      <div
        id="kontakt"
        className="w-full h-screen bg-gradient-to-tr from-slate-300/70 via-stone-100/90 to-slate-400/90 
        
        "
      >
        {/* <FadeIn delay={100}> */}
        {/* <FadeIn transitionDuration={500}> */}
        {modon ? <Modal /> : null}
        <div
          className="max-w-[1440px]  mx-auto 2xl:pt-32 xl:pt-24 md:pt-24 pt-20    h-screen
          lg:w-[80%] xl:w-[85%] 2xl:w-full w-full
        "
        >
          {/* <p className="text-xl font-semibold text-shadow-sm ">Kontakt</p> */}
          {/* <h2 className="py-4">Get In Touch</h2> */}
          {/* <h1 className="text-gray-700">Projekty </h1> */}
          <div className="grid lg:grid-cols-5 gap-12">
            {/* left */}

            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{}}
              className="col-span-3 bg-gray-200 lg:col-span-2 w-full h-full shadow-lg shadow-gray-400 rounded-xl p-4 bg-opacity-80 "
              // data-aos="fade-right"
              // data-aos-duration="500"
              // data-aos-delay="100"
            >
              <div className="lg:p-1 h-full pb-8 ">
                {/* <div>
                  <img
                    className="rounded-xl hover:scale-105 ease-in duration-300"
                    src={ContactImg}
                    alt="/"
                  />
                </div> */}
                {/* <div className="w-full h-full"> */}
                {/* <SimpleMap /> */}
                <div className="pb-4 ">
                  <Mapa_embed />
                </div>
                {/* </div> */}
                <div>
                  {/* Telefon email  */}
                  <div
                    className=" flex flex-col   
               text-stone-700 text-xl pt-0 bg-gray-300 bg-opacity-25 pb-3 rounded-lg"
                  >
                    {/* <p className="pt-4 font-sans text-lg text-shadow-sm"> */}
                    {/* TERMO SERWIS */}
                    <p className="font-sans font-semibold"> TERMO SERWIS</p>
                    <p className="font-sans ">
                      {" "}
                      Autoryzowany Serwis Techniki Grzewczej
                    </p>
                    <div
                      className=" pt-4  justify-center space-x-2  font-sans text-shadow-sm
              flex  "
                    >
                      <AiOutlinePhone size={30} className="" />
                      <span className=" py-0">+48 13 46 35 190</span>
                    </div>
                    <div className=" pt-2  flex justify-center space-x-2  font-sans text-shadow-sm">
                      <AiOutlineMail size={28} className="" />
                      <span className="py-0">biuro@termoserwis24.pl</span>
                    </div>
                    <p className="pt-3 font-sans text-lg text-shadow-sm">
                      NIP: 687-175-26-40 / REGON: 180707866
                    </p>
                    <p className="pt-3 font-sans text-lg text-shadow-sm">
                      Masz pytania? Napisz do nas.
                    </p>
                  </div>
                </div>
                <div>
                  {/* <p className="uppercase pt-8">Connect With Me</p> */}
                </div>
              </div>
            </motion.div>

            {/* right */}
            <motion.div
              initial={{ opacity: 0, x: +1000 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{}}
              transition={{ duration: 0.7 }}
              className="col-span-3 bg-gray-200   w-full h-auto shadow-xl shadow-gray-400 rounded-xl lg:p-4 lg:bg-opacity-80 bg-opacity-95"
              // data-aos="fade-left"
              // data-aos-duration="800"
              // data-aos-delay="200"
            >
              <div className="p-4 text-left font-sans font-semibold md:font-normal">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid md:grid-cols-2 gap-4 w-full py-2">
                    <div className="flex flex-col ">
                      <label className="uppercase text-sm py-2 px-2 ">
                        Imię / Nazwa
                      </label>
                      <input
                        className="border-2 rounded-lg p-3 flex border-gray-300 opacity-50"
                        {...register("imie", { required: true })}
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="uppercase text-sm py-2 px-2">
                        Numer Telefonu
                      </label>
                      <input
                        className="border-2 rounded-lg p-3 flex border-gray-300 opacity-50"
                        {...register("telefon", { required: true })}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-col py-2">
                    <label className="uppercase text-sm py-2 px-2">
                      Adres Email
                    </label>
                    <input
                      className="border-2 rounded-lg p-3 flex border-gray-300 opacity-50"
                      {...register("email", { required: true })}
                      required
                    />
                  </div>
                  <div className="flex flex-col py-2">
                    <label className="uppercase text-sm py-2 px-2">Temat</label>
                    <input
                      className="border-2 rounded-lg p-3 flex border-gray-300 opacity-50"
                      {...register("temat", { required: true })}
                      required
                    />
                  </div>
                  <div className="flex flex-col py-2">
                    <label className="uppercase text-sm py-2 px-2">
                      Wiadomość
                    </label>
                    <textarea
                      className="border-2 rounded-lg p-3 border-gray-300 opacity-50"
                      rows="4"
                      spellCheck="false"
                      {...register("opis", { required: false })}
                      required
                    ></textarea>
                  </div>
                  <div className=" text-center pt-4 h-12 text-sm      md:text-lg">
                    {send === 1 && (
                      <p className="pb-1 font-Inter text-gray-800">
                        Wysyłanie ...{" "}
                      </p>
                    )}

                    {send === 9 && (
                      <p className="pb-1 font-Inter text-green-800">
                        Wiadomość została wyslana..{" "}
                      </p>
                    )}
                    {send === 3 && (
                      <p className="pb-1 font-Inter text-red-800">
                        Wystąpił błąd.. Proszę spróbowć pózniej ..
                      </p>
                    )}
                  </div>
                  <div className="text-center  ">
                    <button
                      className="  rounded-lg text-shadow-sm uppercase bg-slate-500 text-gray-200  text-sm md:text-lg w-28 mt-0   md:mt-8 font-semibold font-sans py-2 hover:bg-slate-600"
                      // onClick={handleSubmit}
                    >
                      Wyślij
                    </button>
                  </div>
                </form>
              </div>
            </motion.div>
          </div>
        </div>
        {/* </FadeIn> */}
        {/* </FadeIn> */}
      </div>
      {/* zamow servis */}
      <div className="hidden lg:flex justify-center items-center opacity-90 ">
        <NavLink to="/serwis">
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0, transition: { type: "linear", delay: 0.7 } }}
            whileHover={{ scale: 1.07, transition: { duration: 0.2 } }}
            className=" bg-red-600 fixed z-90 bottom-1/2 right-0 rounded-l-lg shadow-2xl shadow-gray-800 p-4 cursor-pointer md:h-40 md:w-20 2xl:h-48 2xl:w-24"
          >
            <motion.img
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                rotateY: 360,
                transition: {
                  duration: 1,
                  type: "spring",
                  damping: 10,
                  mass: 0.75,
                  stiffness: 20,
                  delay: 0.7,
                },
              }}
              whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 4px gray",
              }}
              src={ludzik}
              className="w-12 pt-2 mx-auto shadow-2xl "
              alt=""
            />

            <div className=" pt-4 font-sans  text-stone-200 text-lg w-full text-shadow-xl">
              <p>Zamów</p>
              <p>Serwis</p>
            </div>
          </motion.div>
        </NavLink>
      </div>
    </motion.div>
  );
};

export default Kontakt;
