import React from "react";
// import { useState } from "react";
import { Link } from "react-scroll";

// import { BsFillPersonLinesFill } from "react-icons/bs";
// import { FaGithub, FaLinkedinIn } from "react-icons/fa";
// import { BiWrench } from "react-icons/bi";
import FadeIn from "react-fade-in";
import Typed from "react-typed";
import { HiOutlineChevronDoubleUp } from "react-icons/hi";
import kluczN from "../assets/svg/wrench-nice.svg";
import { AiOutlinePhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";

import "react-typed/dist/animatedCursor.css";

import ludzik from "../assets/L1red1czarny_blur.png";
import Logs from "./Logs";
// import Loader from "./Loader";
// import bgt from "../assets/bg-t1.jpg";

import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

const Main = () => {
  // const [lrotate, Setlrotate] = useState(0);

  return (
    <motion.div
      className="bgb"
      initial={{ opacity: 0, x: 0 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      // transition={{ delay: 5 }}

      // transition={{ duration: 1 }}
      // transition={{ duration: 0.2 }}
      key="mine"
    >
      <motion.div
        name="start"
        className=" w-full h-screen text-center  overflow-hidden
                
        "
        // initial={{ opacity: 0, x: 100 }}
        // animate={{ opacity: 1, x: 0 }}
        // exit={{ opacity: 0, duration: 100 }}
        // bg-gray-300 bg-opacity-50 md:bg-opacity-10
        // last bg-gray-800 bg-opacity-10
        //bg-gradient-to-tr from-white/25 via-stone-100/60 to-slate-400/70
        //  bg-gradient-to-tr from-white/75 via-stone-100/95 to-slate-400/90
      >
        <div
          className="max-w-[1640px] w-full h-full mx-auto p-0 flex justify-center md-gap-0 2xl:gap-8 items-start pt-24 md:pt-0 xl:pt-0 
          "
        >
          {/* <p className="xl:text-pink-900 lg:text-gray-600 md:text-gray-900 sm:text-red-400 text-red-800 axl:text-yellow-400 pt-48">
            Mediaaaa
          </p> */}
          {/* kolumny */}
          {/* <div className="grid grid-cols-2 gap-8 "> */}

          <div
            className="hidden lg:flex justify-center items-center opacity-95
          2xl:w-[550px] md:w-[450px] xl:w-[500px] md:pt-24 2xl:pt-52 xl:pt-28 
          "
          >
            <FadeIn delay="500">
              <div className=" ">
                <img
                  // animate={{
                  //   rotateY: lrotate,
                  //   transition: { duration: 0.5, ease: "linear" },
                  // }}
                  src={ludzik}
                  width="550"
                  height="300"
                  className=" hover:scale-[105%] transition duration-500 cursor-pointer "
                  alt=""
                  // onClick={() => Setlrotate(lrotate + 360)}
                ></img>
              </div>
            </FadeIn>
          </div>

          {/* kol1 */}
          <FadeIn delay={1000}>
            <div
              className="md:pt-24 2xl:pt-52 xl:pt-24   "
              // data-aos="fade-down"
              // data-aos-duration="1000"
            >
              {/* kol2 */}
              {/* <p className="uppercase text-sm tracking-widest text-gray-600">
            LET'S BUILD SOMETHING TOGETHER
          </p> */}
              <h1
                className="py-4 text-gray-700 font-sans font-extrabold shadow-slate-500
            text-shadow-xl text-4xl sm:text-5xl md:text-7xl"
              >
                TERMO <span className=" text-red-700">SERWIS</span>
              </h1>
              <h1 className="py-2 text-3xl md:text-6xl text-gray-700 font-bold font-sans text-shadow-xl ">
                Autoryzowany Serwis
              </h1>
              <h1 className="py-2 text-gray-700 font-bold font-sans text-3xl md:text-6xl">
                <span className=" text-shadow-lg ">Techniki</span>
                <span className="text-shadow-lg"> Grzewczej</span>
              </h1>

              {/* <p className="py-4 text-gray-600 sm:max-w-[70%] m-auto">
              I’m focused on building responsive front-end web applications
              while learning back-end technologies.
            </p> */}

              <div
                className="pt-4 font-Caveat font-bold text-2xl md:text-4xl text-gray-600 min-h-[80px]
              text-shadow-sm
            "
              >
                <Typed
                  loop
                  startDelay={2000}
                  typeSpeed={60}
                  backSpeed={30}
                  strings={[
                    "– sprzedaż, montaż i pierwsze uruchomienie",
                    "– serwis gwarancyjny i pogwarancyjny ",
                    "– przeglądy gwarancyjne ",
                    "– przeglądy okresowe ",
                    "– naprawy i usuwanie awarii ",
                    "– profesjonalne doradztwo techniczne ",
                    "– ekspertyzy techniczne ",
                    "– kontrole szczelności instalacji gazowej ",
                    "– kontrole składu spalin (obecność czadu) ",
                    "– usuwanie kamienia kotłowego ",
                    "- uzdatnianie wody ",
                  ]}
                  smartBackspace
                  shuffle={false}
                  backDelay={2000}
                  fadeOut={false}
                  fadeOutDelay={300}
                  loopCount={0}
                  showCursor
                  cursorChar=""
                />
              </div>

              {/* Telefon email  */}
              <div
                className=" flex flex-col   
          text-stone-700 text-xl pt-2"
              >
                <a href="tel:+48134635190" className="">
                  <div
                    className=" pt-1  justify-center space-x-2 font-semibold font-sans text-shadow-lg
            flex  "
                  >
                    <AiOutlinePhone size={36} className="" />
                    <span className=" py-2">+48 13 46 35 190</span>
                  </div>
                </a>
                <a href="mailto:biuro@termoserwis24.pl">
                  <div className=" pt-4 flex justify-center space-x-2 font-semibold font-sans text-shadow-lg">
                    <AiOutlineMail size={36} className="" />
                    <span className="py-2">biuro@termoserwis24.pl</span>
                  </div>
                </a>
              </div>

              {/* kontako + zamow servis mobile  */}
              <div className="md:hidden flex pt-8 justify-center space-x-6">
                <NavLink to="/kontakt">
                  <div
                    className=" flex items-center opacity-90 bg-cyan-600 w-44  rounded-lg shadow-2xl shadow-gray-800 cursor-pointer hover:scale-105 ease-in duration-300
                  p-2"
                  >
                    <div className=" ">
                      {/* <img src={kluczN} className="w-8" /> */}
                    </div>
                    <div className="font-sans  text-stone-200 text-lg w-full text-shadow-xl">
                      <p>Kontakt</p>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/serwis">
                  <div
                    className=" flex items-center opacity-90  bg-red-600 w-44  rounded-lg shadow-2xl shadow-gray-800 cursor-pointer hover:scale-105 ease-in duration-300
                  p-2"
                  >
                    <div className=" ">
                      <img src={kluczN} className="w-8" alt="" />
                    </div>
                    <div className="font-sans  text-stone-200 text-lg w-full text-shadow-xl">
                      <p>Zamów Serwis</p>
                    </div>
                  </div>
                </NavLink>
              </div>
              <Logs />
            </div>
          </FadeIn>
          {/* </div> */}
        </div>
        {/* strzalka w gorę  */}
        <div className="hidden 2xl:flex justify-center py-12">
          <Link to="start">
            <div className="fixed z-90 bottom-16 right-8 rounded-full shadow-lg shadow-gray-400 p-4 cursor-pointer hover:scale-110 ease-in duration-300">
              <HiOutlineChevronDoubleUp className="text-[#2a2a32]" size={30} />
            </div>
          </Link>
        </div>
        {/* zamow servis */}
        <div className="hidden lg:flex justify-center items-center opacity-90 ">
          <NavLink to="/serwis">
            <motion.div
              initial={{ x: 100 }}
              animate={{ x: 0, transition: { type: "linear", delay: 0.7 } }}
              whileHover={{ scale: 1.07, transition: { duration: 0.2 } }}
              className=" bg-red-600  md:h-40 md:w-20 2xl:h-48 2xl:w-24  fixed z-90 bottom-1/2 right-0 rounded-l-lg shadow-2xl shadow-gray-800 p-4 cursor-pointer"
            >
              <motion.img
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  rotateY: 360,
                  transition: {
                    duration: 1,
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 20,
                    delay: 0.7,
                  },
                }}
                whileHover={{
                  scale: 1.1,
                  textShadow: "0px 0px 4px gray",
                }}
                src={ludzik}
                className="w-12 pt-2 mx-auto shadow-2xl "
                alt=""
              />

              <div className=" pt-4 font-sans  text-stone-200 text-lg w-full text-shadow-xl">
                <p>Zamów</p>
                <p>Serwis</p>
              </div>
            </motion.div>
          </NavLink>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Main;
