import { AnimatePresence } from "framer-motion";
import React from "react";

import { Routes, Route, useLocation } from "react-router-dom";

import Kontakt from "../comps/Kontakt";
import Main from "../comps/Main";
import Oferta from "../comps/Oferta";
// import Onas from "../comps/Onas";
import Projekty from "../comps/Projekty";

import Loader from "./Loader";
import Serwis from "./serwis/Serwis";
// import Test from "./Test";
// import Loader from "./comps/Loader";
import { useEffect, useState } from "react";
// import Navbar from "./Navbar";

const Nav = () => {
  return <div>Navbar</div>;
};

function Aroutes() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2500); //2800
    // console.log("1111");
  }, []);

  const location = useLocation();
  return (
    <>
      {/* {loading === false ? (
        <>
          <Navbar />

          <AnimatePresence exitBeforeEnter>
            <Navbar />
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Main />} />
              <Route path="/oferta" element={<Oferta />} />
              
              <Route path="/projekty" element={<Projekty />} />
              <Route path="/kontakt" element={<Kontakt />} />
              <Route path="/loader" element={<Loader />} />
              <Route path="/test" element={<Test />} />
              <Route path="/serwis" element={<Serwis />} />
            </Routes>
          </AnimatePresence>
        </>
      ) : (
        <AnimatePresence exitBeforeEnter>


          <Loader />
        </AnimatePresence>
      )} */}

      {loading ? (
        <AnimatePresence mode="wait">
          <Loader />
        </AnimatePresence>
      ) : (
        <AnimatePresence mode="wait">
          {/* <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Main />} />
            <Route path="/oferta" element={<Oferta />} />

            <Route path="/projekty" element={<Projekty />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/loader" element={<Loader />} />
            <Route path="/test" element={<Test />} />
            <Route path="/serwis" element={<Serwis />} />
          </Routes> */}
          {/* <motion.div exit={{ opacity: 0 }} key="zzx">
            <Navbar />
          </motion.div> */}
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Main />} />
            <Route path="/oferta" element={<Oferta />} />

            <Route path="/projekty" element={<Projekty />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/loader" element={<Loader />} />
            {/* <Route path="/test" element={<Test />} /> */}
            <Route path="/serwis" element={<Serwis />} />
          </Routes>
        </AnimatePresence>
      )}
    </>
  );
}

export default Aroutes;
