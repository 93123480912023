import React from "react";
import { motion } from "framer-motion";
// import kluczN from "../assets/svg/wrench-nice.svg";
import ludzik from "../assets/L1red1czarny_blur.png";

// import load from "../../public/wrench1.svg";

import { NavLink } from "react-router-dom";

import jpg1 from "../assets/realizacje/1.jpg";
import jpg2 from "../assets/realizacje/2.jpg";
import jpg3 from "../assets/realizacje/3.jpg";
import jpg4 from "../assets/realizacje/4.jpg";
import jpg5 from "../assets/realizacje/5.jpg";
import jpg6 from "../assets/realizacje/6.jpg";
import jpg7 from "../assets/realizacje/7.jpg";
import jpg8 from "../assets/realizacje/8.jpg";
import jpg9 from "../assets/realizacje/9.jpg";
import jpg10 from "../assets/realizacje/10.jpg";
import jpg11 from "../assets/realizacje/11.jpg";
import jpg12 from "../assets/realizacje/12.jpg";
import jpg13 from "../assets/realizacje/13.jpg";
import jpg14 from "../assets/realizacje/14.jpg";
import jpg15 from "../assets/realizacje/15.jpg";
import jpg16 from "../assets/realizacje/16.jpg";
import jpg17 from "../assets/realizacje/17.jpg";
// bosh buderus deditrich ariston

import ResponsiveGallery from "react-responsive-gallery";

function Projekty() {
  // const [apidata, setApidata] = useState("");
  // const { scrollYProgress } = useScroll();

  const images = [
    {
      src: jpg15,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg16,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg17,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg1,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg2,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg3,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg4,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg5,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg6,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg7,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg8,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg9,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg10,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg11,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg12,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg13,
      imgClassName: "testbb",
      loading: "lazy",
    },
    {
      src: jpg14,
      imgClassName: "testbb",
      loading: "lazy",
    },
  ];

  return (
    <motion.div
      className="bgbprojekty -z-10"
      initial={{ opacity: 0, x: 0 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0 }}
      // transition={{ duration: 1 }}
    >
      {/* <Navbar /> */}
      <div
        className=" w-full h-full 
       overflow-auto"
      >
        <div
          name="projekty"
          className="bgbprojekty w-full h-full text-center  overflow-auto  "
        >
          <div className="w-full h-full overflow-auto bg-slate-300 bg-opacity-70">
            <div className="max-w-[1440px] w-full mx-auto p-2 flex flex-col justify-center items-center  ">
              <div className="flex justify-center items-center pt-24">
                <motion.h1
                  className="text-gray-700 text-[40px] md:text-[60px] text-shadow-md font-Teko"
                  initial={{ opacity: 0, y: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  // transition={{ duration: 1 }}
                >
                  Nasze Realizacje{" "}
                </motion.h1>
                {/* 
            <img
              src={klucz}
              alt="React Logo"
              className=" ml-4  w-10 animate-spin"
            /> */}
              </div>
              <motion.div
                className=" pt-2 w-full relative pb-8 h-[700px] overflow-scroll scrollbar-hide overflow-x-hidden"
                initial={{ opacity: 0, x: 0 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1, delay: 0.7 },
                }}
                exit={{ opacity: 0 }}

                // transition={{ duration: 1 }}
              >
                <ResponsiveGallery
                  useLightBox
                  // customLoader={ludzik}
                  images={images}
                  colsPadding={{
                    xs: 5,
                    s: 10,
                    m: 10,
                    l: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                  imagesPaddingBottom={{
                    xs: 10,
                    s: 10,
                    m: 10,
                    l: 10,
                    xl: 10,
                    xxl: 10,
                  }}
                />
              </motion.div>

              {/* <div className="flex justify-center items-center pt-20 gap-4">
            <button onClick={TestSub}>zzzzzzzzzzzzzzz</button>
            <p className=""> {apidata}</p>
          </div> */}
            </div>
            <div className="footer p-1 md:p-3"></div>
            {/* strzalka w gorę  */}
            {/* <div className="hidden lg:flex justify-center py-12">
            <Link to="#">
              <div className="fixed z-90 bottom-16 right-8 rounded-full shadow-lg shadow-gray-400 p-4 cursor-pointer hover:scale-110 ease-in duration-300">
                <HiOutlineChevronDoubleUp
                  className="text-[#2a2a32]"
                  size={30}
                />
              </div>
            </Link>
          </div> */}
          </div>
        </div>
      </div>
      {/* zamow serwis  */}
      <div className="hidden lg:flex justify-center items-center opacity-90 ">
        <NavLink to="/serwis">
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0, transition: { type: "linear", delay: 0.7 } }}
            whileHover={{ scale: 1.07, transition: { duration: 0.2 } }}
            className=" bg-red-600  fixed z-90 bottom-1/2 right-0 rounded-l-lg shadow-2xl shadow-gray-800 p-4 cursor-pointer md:h-40 md:w-20 2xl:h-48 2xl:w-24"
          >
            <motion.img
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                rotateY: 360,
                transition: {
                  duration: 1,
                  type: "spring",
                  damping: 10,
                  mass: 0.75,
                  stiffness: 20,
                  delay: 0.7,
                },
              }}
              whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 4px gray",
              }}
              src={ludzik}
              className="w-12 pt-2 mx-auto shadow-2xl "
              alt=""
            />

            <div className=" pt-4 font-sans  text-stone-200 text-lg w-full text-shadow-xl">
              <p>Zamów</p>
              <p>Serwis</p>
            </div>
          </motion.div>
        </NavLink>
      </div>
    </motion.div>
  );
}
export default Projekty;
