import React from "react";
// import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
// import Navbar from "./comps/Navbar";
// import Loader from "./comps/Loader";

const root = ReactDOM.createRoot(document.getElementById("root"));

// const App = React.lazy(() => import("./App"));
// const Navbar = React.lazy(() => import("./comps/Navbar"));

root.render(
  //  <React.StrictMode>
  <>
    {/* <Navbar /> */}

    <App />
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
