import React from "react";
import { motion } from "framer-motion";
// import klucz from "../assets/svg/wrench1.svg";
import Lottie from "lottie-react";
import lklucz from "../assets/lottie/10531-loading-wrench-a.json";

function Loader() {
  return (
    <motion.div
      key="loaderN"
      className="bgb loader overflow-hidden scrollbar-hide"
      initial={{ opacity: 0, x: 0 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 0, transition: { duration: 0.8 } }}
      // transition={{ duration: 2 }}
    >
      {/* <div className=" z-50"> */}
      <div className="w-full h-screen text-center bg-gradient-to-tr from-white/75 via-stone-100/95 to-slate-400/90">
        <div className="max-w-[1240px] w-full h-full mx-auto p-2 flex justify-center items-center">
          {/* <div>
            <img
              src={klucz}
              alt="React Logo"
              className="  w-24 md:w-40 animate-spin"
            />
          </div> */}
          {/* <Vklucz /> */}
          <Lottie animationData={lklucz} loop={true} />
        </div>
      </div>
    </motion.div>
  );
}

export default Loader;
