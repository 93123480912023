import React from "react";

function Mapa_embed() {
  return (
    <>
      <div className="hidden md:flex justify-center z-0 ">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1152.715183824003!2d22.187046103475172!3d49.564591792029795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473c6d759b447367%3A0xe08868e71b812c36!2sTermo%20Serwis%2C%20Autoryzowany%20Partner%20Bosch%20Termotechnika%2C%20Buderus!5e0!3m2!1spl!2spl!4v1659459221708!5m2!1spl!2spl"
          width="500"
          height="400"
          // style={{ border: "0" }}
          // allowfullscreen=""
          allowFullScreen={true}
          loading="lazy"
          title="mapaEmbed"
          className=" rounded-lg pb-2 "
        ></iframe>
      </div>

      <div className="md:hidden  flex shadow-xl justify-center items-center m-auto">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1152.715183824003!2d22.187046103475172!3d49.564591792029795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473c6d759b447367%3A0xe08868e71b812c36!2sTermo%20Serwis%2C%20Autoryzowany%20Partner%20Bosch%20Termotechnika%2C%20Buderus!5e0!3m2!1spl!2spl!4v1659459221708!5m2!1spl!2spl"
          width="300"
          height="300"
          // style={{ border: "0" }}
          // allowfullscreen=""
          allowFullScreen={true}
          loading="lazy"
          title="mapaEmbed"
          className=" rounded-lg pb-4  "
        ></iframe>
      </div>
    </>
  );
}

export default Mapa_embed;
