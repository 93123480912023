import React, { useState } from "react";
//import { Link } from "react-scroll";
import { Link } from "react-router-dom";
// import FadeIn from "react-fade-in/lib/FadeIn";
import { motion } from "framer-motion";

import {
  AiOutlineClose,
  AiOutlineMail,
  AiOutlineMenu,
  AiOutlinePhone,
} from "react-icons/ai";
// import { FaGithub, FaLinkedinIn } from "react-icons/fa";
// import { BsFillPersonLinesFill } from "react-icons/bs";
// import { BiWrench } from "react-icons/bi";
// import { useRouter } from 'next/router';
import NavLogo from "../assets/4.png";
import ludzik from "../assets/L1red1czarny_blur.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  // const [shadow, setShadow] = useState(false);
  // const navBg = "#ecf0f3";
  //   const [linkColor, setLinkColor] = useState("#1f2937");
  // const [position, setPosition] = useState('fixed')
  // const router = useRouter();

  // useEffect(() => {
  //   if (
  //     router.asPath === '/property' ||
  //     router.asPath === '/crypto' ||
  //     router.asPath === '/netflix' ||
  //     router.asPath === '/twitch'
  //   ) {
  //     setNavBg('transparent');
  //     setLinkColor('#ecf0f3');
  //   } else {
  //     setNavBg('#ecf0f3');
  //     setLinkColor('#1f2937');
  //   }
  // }, [router]);

  const handleNav = () => {
    setNav(!nav);
  };

  // useEffect(() => {
  //   const handleShadow = () => {
  //     if (window.scrollY >= 90) {
  //       console.log("truee");
  //       // setShadow(true);
  //     } else {
  //       // setShadow(false);
  //       console.log("Falseee");
  //     }
  //   };
  //   window.addEventListener("scroll", handleShadow);
  //   console.log("navbar_use effect..");
  // }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 1, delay: 3 } }}
      exit={{ opacity: 1 }}
      // className="gridnav"
      // data-aos="fade-down"
      // data-aos-duration="1000"
      // style={{ backgroundColor: `${navBg}` }}
      // className={
      //   shadow
      //     ? "fixed w-full h-20 shadow-xl z-[100] ease-in-out duration-300"
      //     : "fixed w-full h-20 z-[100]"
      // }
      className=" fixed w-full h-20 shadow-lg   md:opacity-90  opacity-90  z-50"
      id="navbar"
    >
      <div
        className=" z-50 fixed w-full h-20 shadow-lg  bg-gradient-to-b from-gray-200/90 to-gray-300/70
         "
        // className=" z-auto fixed w-full h-20 shadow-lg bg-gray-200 bg-opacity-90  "
        // style={{ backgroundImage: `${ludzik}` }}
      >
        <div
          className=" flex justify-between items-center w-full h-full px-4 2xl:px-16
       
      "
          id="Navbar"
        >
          <Link to={"/"}>
            <div className="hidden md:flex">
              <img
                src={NavLogo}
                alt=""
                width={325}
                height={250}
                className="cursor-pointer mt-0 md:mt-2 xl:mt-6 shadow-lg rounded-xl
                xl:text-pink-400 lg:text-gray-600 md:text-gray-900 sm:text-red-400 text-red-800 axl:text-yellow-400
                "
              />
            </div>
            <div className="flex md:hidden">
              <img
                src={NavLogo}
                alt=""
                width={215}
                height={140}
                className="cursor-pointer mt-0 md:mt-2 xl:mt-6 shadow-lg"
              />
            </div>
          </Link>
          <div>
            <ul className="hidden md:flex  font-semibold text-lg  uppercase  font-sans bg ">
              <li
                className=" ml-10 hover:text-pink-800 duration-150 hover:scale-[115%] 
                hover-underline-animation 
            "
              >
                <Link to="/" className="">
                  Start
                </Link>
              </li>
              <li className="ml-8 hover:text-red-800 duration-150 hover:scale-[115%] hover-underline-animation ">
                <Link to="/oferta">Oferta</Link>
              </li>
              {/* <li className="ml-8 hover:text-red-800 duration-150 hover:scale-[115%] hover-underline-animation ">
                <Link to="/firma">Firma</Link>
              </li> */}
              <li className="ml-8 hover:text-red-800 duration-150 hover:scale-[115%] hover-underline-animation ">
                <Link to="/projekty">Realizacje</Link>
              </li>

              <li className="ml-8 hover:text-red-800 duration-150 hover:scale-[115%] hover-underline-animation ">
                <Link to="/kontakt">Kontakt</Link>
              </li>

              <li className="ml-8 text-indigo-900 hover:text-red-800 duration-150 hover:scale-[115%] hover-underline-animation ">
                <a
                  href="http://termoserwis24.pl/buderus/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buderus
                </a>
              </li>
            </ul>
            {/* Hamburger Icon */}
            <div
              // style={{ color: `${linkColor}` }}
              onClick={handleNav}
              className="md:hidden hover:text-red-600 cursor-pointer"
            >
              <AiOutlineMenu size={25} />
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {/* Overlay */}
        <div
          className={
            nav
              ? "md:hidden fixed left-0 top-0 w-full h-screen bg-black/70"
              : ""
          }
        >
          {/* Side Drawer Menu */}
          <div
            className={
              nav
                ? " fixed z-[10050] left-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] h-screen p-6 ease-in duration-500 bg-gradient-to-b from-gray-100 to-gray-400 "
                : "fixed left-[-100%] top-0 p-10 ease-in duration-100"
            }
          >
            <div className="">
              <div className="flex w-full items-center justify-between ">
                <Link to={"/"}>
                  <img src={ludzik} width="107" height="50" alt="/" />
                </Link>
                <div
                  onClick={handleNav}
                  className="rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer"
                >
                  <AiOutlineClose />
                </div>
              </div>
              <div className="border-b border-gray-400 my-4">
                <p className="w-[85%] md:w-[90%] py-2"></p>
              </div>
            </div>
            <div className="py-1 flex flex-col">
              <ul className=" uppercase font-semibold">
                <li className="ml-10 pt-2 border-b border-gray-400">
                  <Link to="/">
                    <span
                      className=" hover:font-bold"
                      onClick={() => setNav(false)}
                    >
                      Start
                    </span>
                  </Link>
                </li>

                <li className="ml-10 pt-4 border-b border-gray-400  ">
                  <Link to="oferta">
                    <span
                      className=" hover:font-bold"
                      onClick={() => setNav(false)}
                    >
                      Oferta
                    </span>
                  </Link>
                </li>
                {/* <li className="ml-10 pt-4 border-b border-gray-400">
                  <Link to="/firma">
                    <span
                      className=" hover:font-bold"
                      onClick={() => setNav(false)}
                    >
                      Firma
                    </span>
                  </Link>
                </li> */}
                <li className="ml-10 pt-4 border-b border-gray-400 ">
                  <Link to="projekty">
                    <span
                      className=" hover:font-bold"
                      onClick={() => setNav(false)}
                    >
                      Realizacje
                    </span>
                  </Link>
                </li>
                <li className="ml-10 pt-4 border-b border-gray-400">
                  <Link to="kontakt">
                    <span
                      className=" hover:font-bold"
                      onClick={() => setNav(false)}
                    >
                      {" "}
                      Kontakt{" "}
                    </span>
                  </Link>
                </li>
                <li className="ml-10 pt-4 text-blue-800">
                  <a
                    href="http://termoserwis24.pl/buderus/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      className=" hover:font-bold"
                      onClick={() => setNav(false)}
                    >
                      Buderus
                    </span>
                  </a>
                </li>
              </ul>
              <div className="border-b border-gray-400 my-2">
                <p className="w-[65%] md:w-[90%] pt-4"></p>
              </div>
              <div className=" pt-4 flex justify-center space-x-2 font-semibold font-sans text-shadow-sm">
                <AiOutlinePhone size={22} />
                <span> 13 46 35 190</span>
              </div>
              <div className=" pt-2 flex justify-center space-x-2 font-semibold font-sans text-shadow-sm">
                <AiOutlineMail size={22} className="" />
                <span>biuro@termoserwis24.pl</span>
              </div>
              <div className=" pt-2 flex text-gray-700 justify-center space-x-2 font-normal font-Caveat text-shadow-sm">
                {/* <a */}
                {/* href="http://aw-tech.pl"
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                Realizacja AW-TECH.PL
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Navbar;
